<template>
  <div>
    <div class="container">
      <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 sctp-flex subMenu-wrap">
        <template v-for="menu in menus">
          <div :id="menu.name">
            <router-link :to="menu.url">{{ menu.name }}</router-link>
          </div>
        </template>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'account',
  components: {},
  data() {
    return {
      menus: [
        {name: '快速充值', url: '/account/rechagre'},
        {name: '我要提现', url: '/user/account/withdraw'},
        {name: '提现记录', url: '/user/account/withdraw_record'},
        {name: '支付密码', url: '/user/account/paypwd'},
        {name: '收款码', url: '/user/account/collectionCode'},
        {name: '登录密码', url: '/user/account/password'},
      ]
    }
  },
  methods: {},
  beforeMount() {
  },
  computed: {}
}
</script>

<style scoped>
/*@formatter:off*/
  .container .subMenu-wrap {height: 49px;font-size: 15px;font-weight: 700;}
  .container .subMenu-wrap > div {border-right: 1px solid #e5e5e5;padding: 0 25px;}
  .container .router-link-active {color: #FF9900!important;}
  /*@formatter:on*/
</style>
